(function() {
  var remember = document.querySelector("input[name='husk-mig[]']");
  var submitButton = document.querySelector("input[type='submit']");
  var savedData = JSON.parse(localStorage.getItem("remember"));

  if (savedData && remember) {
    var keys = Object.keys(savedData);
    var values = Object.values(savedData);
    
    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      var input = document.querySelector("input[name=" + CSS.escape(key) + "]");
      if (input) {
        input.value = values[i];
      }
    }

    remember.checked = true;
  }

  if (remember && submitButton) {
    submitButton.addEventListener("click", function() {
      var fieldGroup = document.querySelectorAll(".formul__section");

      if (remember.checked) {
        if (fieldGroup) {
          var fields = fieldGroup[0].querySelectorAll("input");
          var object = {};
          for (var i = 0; i < fields.length; i++) {
            var field = fields[i];
            object[field.name] = field.value;
          }
        }

        localStorage.setItem("remember", JSON.stringify(object));
      }
    });
  }

  return;
})();