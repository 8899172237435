(function () {

    var sticky = document.querySelector('.cactus-sticky-add-to-cart');

    if (sticky) {
        var button = document.querySelector('.summary .single_add_to_cart_button');
        var buttonPosition = button.getBoundingClientRect().top + window.scrollY;

        // Hide Sticky outside window on init
        sticky.style.top = '-' + (sticky.clientHeight + 5) + 'px';

        // Add Event Listener for Scroll and add Active class if window is under add-to-cart button
        window.addEventListener('scroll', function () {
            if (window.pageYOffset > buttonPosition) {
                sticky.style.top = '0';
            } else {
                sticky.style.top = '-' + (sticky.clientHeight + 5) + 'px';
            }
        });

        document.addEventListener('DOMContentLoaded', function () {
            if (window.pageYOffset > buttonPosition) {
                sticky.style.top = '0';
            } else {
                sticky.style.top = '-' + (sticky.clientHeight + 5) + 'px';
            }
        });

    }

})();
