/*
 * Custom JS file
 */
$(document).ready(function() {

    /**
     * Add Ajax Search to All Inputs with type Search
     */
    searchInputs = document.querySelectorAll('input[type=search]');
    searchInputs.forEach(function(input) {
        new AjaxSearch(input);
    });
    
    /* Shop filter toggle */
    $('.collapsible').click(function () {
      $('.collapsible').toggleClass("active");
    });
    
    /* Sidebar category collapse */
    $("<div class='woo-cat-toggle'></div>").insertAfter("#secondary .widget_product_categories .product-categories .cat-item.cat-parent > a");
    $("#secondary .widget_product_categories .product-categories .woo-cat-toggle").click(function () {
        $(this).toggleClass("cat-popped");
    });
    $('#secondary .widget_product_categories .product-categories .cat-item.cat-parent').each(function () {
        if ($(this).is('.current-cat, .current-cat-parent')) {
            $(this).children('.woo-cat-toggle').toggleClass("cat-popped");
        }
    });

    /* Adds dropdown arrow to menu */
    $(".sub-menu").parent("li").addClass("menu-item-has-children");

    /* Removes unnecessary background class */
    $(".actions").parent().addClass("no-bg");
    
    /* Sidebar */
    if ($("#secondary.active-scroll").length && $(window).width() >= 993) {
        $(window).scroll(asideBar);
        asideBar();

        function asideBar() {
            var windowHeight = $(window).height();
            var browserWindow = $(window).scrollTop();
            var mainContent = $("#content").offset().top;
            var primaryHeight = $("#primary").height();
            var footer = $("footer").offset().top;
            var secondarySidebar = $("#secondary");
            var secondaryHeight = $("#secondary").height();
            var myTop = 60; // margin
            var secondaryHeightAndmyTop = $("#secondary").height() + myTop;

            if (primaryHeight >= secondaryHeight) {
                if (secondaryHeight > windowHeight) {
                    secondarySidebar.css({
                        height: "100%"
                    });
                }
                if (browserWindow >= mainContent) {
                    var myPosition = "fixed";
                    if ($(window).scrollTop() + myTop > footer - secondaryHeightAndmyTop) {
                        myTop = footer - secondaryHeightAndmyTop;
                        myPosition = "absolute";
                    }

                    secondarySidebar.css({
                        position: myPosition,
                        top: myTop,
                        bottom: ""
                    });
                } else {
                    if (browserWindow <= mainContent) {
                        secondarySidebar.css({
                            position: "absolute",
                            top: "",
                            bottom: ""
                        });
                    }
                }
            }
        };
    }
    
    // Owl carousel
    if($('.owl-carousel').length){
        // Owl slider banner
        var owl = jQuery('.owl-carousel-banner');
        owl.owlCarousel({
            items: 1,
            lazyLoad: true,
            dots: false,
            loop: true,
            nav: false,
            margin: 0,
            autoplay: true,
            autoplayTimeout: 6000,
            autoplayHoverPause: true,
            autoHeight:true,
            animateIn: 'fadeIn',
            animateOut: 'fadeOut',
            mouseDrag: false,
            touchDrag: false,
            pullDrag: false,
            freeDrag: false
        });

        // Owl slider reference
        var owl = jQuery('.owl-carousel-reference');
        owl.owlCarousel({
            lazyLoad: true,
            nav:true,
            navText : ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'],
            dots: false,
            loop:true,
            margin:20,
            autoplayTimeout: 6000,
            autoplayHoverPause: true,
            responsive:{
                0:{
                    items:1
                },
                600:{
                    items:3
                },
                1000:{
                    items:4
                }
            }
        });

        // Owl slider featured
        var owl = jQuery('.owl-carousel-featured');
        owl.owlCarousel({
            lazyLoad: true,
            nav:true,
            navContainer: false,
            navText : ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'],
            dots: false,
            loop:false,
            margin: 32,
            responsive:{
                0:{
                    items:1,
                    stagePadding: false
                },
                480: {
                    items: 2
                },
                768:{
                    items: 3
                }
            }
        });

        // Owl slider best sellers
        var owl = jQuery('.owl-carousel-best-sellers');
        owl.owlCarousel({
            lazyLoad: true,
            nav:true,
            navContainer: false,
            navText : ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'],
            dots: false,
            loop:false,
            margin: 32,
            responsive:{
                0:{
                    items: 1,
                    stagePadding: false
                },
                480: {
                    items: 2
                },
                768:{
                    items: 3
                }
            }
        });
    }

    // Gallery image popup
    if($('.image-popup-vertical-fit').length){
        $('.image-popup-vertical-fit').magnificPopup({
            type: 'image',
            mainClass: 'mfp-with-zoom',
            gallery: {
                enabled: true,
                preload: [0,2]
            },
            zoom: {
                enabled: true,
                duration: 300, // duration of the effect, in milliseconds
                easing: 'ease-in-out', // CSS transition easing function

                opener: function (openerElement) {
                    return openerElement.is('img') ? openerElement : openerElement.find('img');
                }
            },
            callbacks: {
              lazyLoad: function(item) {
                return item; // Magnific Popup data object that should be loaded
              }
            }
        });
        
        // lazyload
        (function (global) {
          var $q = function(q, res){
                if (document.querySelectorAll) {
                  res = document.querySelectorAll(q);
                } else {
                  var d=document
                    , a=d.styleSheets[0] || d.createStyleSheet();
                  a.addRule(q,'f:b');
                  for(var l=d.all,b=0,c=[],f=l.length;b<f;b++)
                    l[b].currentStyle.f && c.push(l[b]);

                  a.removeRule(0);
                  res = c;
                }
                return res;
              }
            , addEventListener = function(evt, fn){
                window.addEventListener
                  ? this.addEventListener(evt, fn, false)
                  : (window.attachEvent)
                    ? this.attachEvent('on' + evt, fn)
                    : this['on' + evt] = fn;
              }
            ;

          function loadImage (el, fn) {
            var img = new Image()
              , src = el.getAttribute('data-src');
            img.onload = function() {
              if (!! el.parent)
                el.parent.replaceChild(img, el);
              else
                el.src = src;

              fn? fn() : null;
            };
            img.src = src;
          }

          function elementInViewport(el) {
            var rect = el.getBoundingClientRect();

            return (
               rect.top    >= 0
            && rect.left   >= 0
            && rect.top <= (window.innerHeight || document.documentElement.clientHeight)
            );
          }

            var images = new Array()
              , query = $q('img.lazy')
              , processScroll = function(){
                  for (var i = 0; i < images.length; i++) {
                    if (elementInViewport(images[i])) {
                      loadImage(images[i], function () {
                        images.splice(i, i);
                      });
                    }
                  };
                }
              ;
            // Array.prototype.slice.call is not callable under our lovely IE8 
            for (var i = 0; i < query.length; i++) {
              images.push(query[i]);
            };

            processScroll();
            addEventListener('scroll',processScroll);

        })(this);
    }

});


/* 
    Add 'Active' class to product filter to keep it open after reload.
*/
document.addEventListener('DOMContentLoaded', function() {

    var filtered = document.getElementById('woocommerce_layered_nav_filters-2'),
        filterButton = document.getElementsByClassName('collapsible')[0];

    if (filtered) {
        filterButton.classList.add("active");
    }

});
